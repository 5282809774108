<template>
  <v-app-bar
    class="app-bar"
    flat
    height="70"
  >
    <div class="app-bar__container d-flex flex-1-1 align-center justify-between">
      <div class="app-bar__container-logo d-flex align-center justify-center">
        <nuxt-link to="/">
          <NuxtImg
            src="/AmzSi_logo.svg"
            width="88"
          />
        </nuxt-link>
      </div>
      <v-divider
        color="light"
        opacity="100"
        vertical
      />
      <slot
        v-if="hasPrependSlot"
        name="prepend"
      />
      <v-spacer />
      <v-divider
        v-if="hasAppendSlot"
        color="light"
        opacity="100"
        vertical
      />
      <slot
        v-if="hasAppendSlot"
        name="append"
      />
    </div>
  </v-app-bar>
</template>

<script lang="ts" setup>
const slots = useSlots();
const hasAppendSlot = computed(() => !!slots.append);
const hasPrependSlot = computed(() => !!slots.prepend);
</script>

<style lang="scss" scoped>
.app-bar {
  border-bottom: 1px solid rgb(var(--v-theme-light));
  &__container {
    max-width: 1440px;
    margin: 0 auto;
    height: 100%;
    padding-inline:  2rem;
  }
  &__container-logo {
    width: 144px;
  }
}

:deep(.v-toolbar__prepend) {
  margin-inline: 0;
}
</style>
